import React from 'react';
import BackgroundImage from '../../background-image';
import NavBar from '../../Navbar/index';
import useStyles from './styled';
import Content from './Content';

export default function Header() {
  const classes = useStyles();

  return (
    <>
      <NavBar transparent />
      <div className={classes.root}>
        <BackgroundImage />
        <Content />
      </div>
    </>
  );
}
