import makeStyles from '../../../ui/make-styles';
import { colors } from '../../../ui/common';

export default makeStyles((theme) => ({
  container: {
    marginTop: 84,
  },
  container2: {
    marginTop: 20,
  },
  itemTitle: {
    fontSize: '32px !important',
    fontWeight: '600 !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px !important',
    },
  },
  itemText: {
    fontSize: '14px !important',
    fontWeight: '300 !important',
    margin: '0 auto',
    whiteSpace: 'pre-wrap',
    padding: '0 35px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '0 20px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px !important',
      padding: '0 10px',
    },
  },
  radioGroup: {
    paddingTop: 23,
  },
  button: {
    paddingTop: 26,
    paddingBottom: 58,
  },
  button2: {
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 58,
    },
  },
  cardContainer: {
    background: colors.color10,
    borderRadius: 4,
    textAlign: 'center',
    boxShadow: 'none',
  },
  cardContainer2: {
    height: 500,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  imgPreview: {
    height: 300,
    margin: '0 auto',
  },
  grid: {
    overflow: 'hidden',
  },
  containerFull: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  itemFull: {
    flex: '50%',
  },
  fullImg: {
    width: '85%',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      height: '300px',
    },
  },
}));
