import designed from '../../../images/home-page/reasons/designed-for.svg';
import consumption from '../../../images/home-page/reasons/consumption.svg';
import installation from '../../../images/home-page/reasons/installation-cost.svg';
import freon from '../../../images/home-page/reasons/no-freon.svg';
import noise from '../../../images/home-page/reasons/noise.svg';
import humidifies from '../../../images/home-page/reasons/humidifies.svg';
import portable from '../../../images/home-page/reasons/portable.svg';
import awarded from '../../../images/home-page/reasons/award.svg';
import disturb from '../../../images/home-page/reasons/disturb.svg';

export default [
  {
    image: designed,
    title: 'home_reasons.designed',
    values: [
      'home_reasons.designed.model1',
      'home_reasons.designed.model2',
      'home_reasons.designed.model3',
    ],
  },
  {
    image: consumption,
    title: 'home_reasons.consumption',
    values: [
      'home_reasons.consumption.model1',
      'home_reasons.consumption.model2',
      'home_reasons.consumption.model3',
    ],
  },
  {
    image: installation,
    title: 'home_reasons.installation',
    values: [
      'home_reasons.installation.model1',
      'home_reasons.installation.model2',
      'home_reasons.installation.model3',
    ],
  },
  {
    image: freon,
    title: 'home_reasons.freon',
    values: [1, 0, 1],
  },
  {
    image: noise,
    title: 'home_reasons.noise',
    values: [
      'home_reasons.noise.model1',
      'home_reasons.noise.model2',
      'home_reasons.noise.model3',
    ],
  },
  {
    image: humidifies,
    title: 'home_reasons.humidifies',
    values: [1, 0, 0],
  },
  {
    image: portable,
    title: 'home_reasons.portable',
    values: [1, 0, 1],
  },
  {
    image: awarded,
    title: 'home_reasons.awarded',
    values: [1, 0, 0],
  },
  {
    image: disturb,
    title: 'home_reasons.disturb',
    values: [1, 0, 0],
  },
];
