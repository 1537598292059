import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from './make-styles';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: '0 auto',
    paddingTop: 115,
    textAlign: 'center',
    fontSize: '38px',
    fontWeight: '600',
    [theme.breakpoints.down('md')]: {
      paddingTop: 80,
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 55,
      fontSize: '32px',
    },
  },
  titleSmall: {
    paddingTop: 20,
    textAlign: 'center',
    fontWeight: '300',
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      padding: '0px 15px',
    },
  },
}));

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
function Title(props) {
  const classes = useStyles();

  return (
    <Typography
      paragraph
      variant="h4"
      className={classNames(classes.title, props.className)}
    >
      {props.children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Title.defaultProps = {
  className: '',
};

export { Title };

function TitleSmall(props) {
  const classes = useStyles();

  return (
    <Typography
      paragraph
      variant="h6"
      className={classNames(classes.titleSmall, props.className)}
    >
      {props.children}
    </Typography>
  );
}

TitleSmall.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TitleSmall.defaultProps = {
  className: '',
};

export { TitleSmall };
/* eslint-enable react/jsx-props-no-spreading */
/* eslint-enable react/destructuring-assignment */

export const Title1 = styled(Typography)`
  font-weight: 300;
  font-size: 32px;
  line-height: 170%;
`;

export const Title2 = styled(Typography)`
  font-weight: 600;
  font-size: 22px;
  line-height: 152.9%;
`;

export const Caption1 = styled(Typography)`
  font-weight: normal;
  font-size: 12px;
  line-height: 189.9%;
`;

export const Caption2 = styled(Typography)`
  font-weight: normal;
  font-size: 12px;
  line-height: 189.9%;
`;
