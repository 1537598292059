import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import useI18nNavigation from '../../hooks/use-i18n-navigation';
import { ButtonBig } from '../../ui/buttonDefault';

function ShopNow({ extended }) {
  const navigate = useI18nNavigation();
  const handleClick = () => {
    navigate('/shop');
  };

  return (
    <ButtonBig onClick={handleClick} extended={extended}>
      <FormattedMessage id="main.shop_now" />
    </ButtonBig>
  );
}

ShopNow.propTypes = {
  extended: PropTypes.bool,
};

ShopNow.defaultProps = {
  extended: false,
};

export default ShopNow;
