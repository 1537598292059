import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import Background from '../../../ui/background';
import useStyles from './styled';
import items from './data';
import Container from '../../../ui/Container';

export default function Benefits() {
  const classes = useStyles();

  return (
    <Background className={classes.root}>
      <Container maxWidth="lg">
        <Typography paragraph variant="h5" className={classes.title}>
          <FormattedMessage id="benefits.title" />
        </Typography>
        <Grid container className={classes.container}>
          {items.map((value) => (
            <Grid item md={3} xs={6} className={classes.paper} key={value}>
              <img src={value[0]} alt="icon" className={classes.icon} />
              <Typography paragraph variant="h5" className={classes.descTitle}>
                <FormattedMessage id={value[1]} />
              </Typography>
              <Typography paragraph variant="h6" className={classes.descText}>
                <FormattedMessage id={value[2]} />
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Background>
  );
}
