import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import Container from '@material-ui/core/Container';
import { Title } from '../../../ui/text';
import Background from '../../../ui/background';
import ComparisonTable from '../../Common/ComparisonTable';
import list from './data';
import useStyles from './styled';

export const images = graphql`
  query {
    model1: file(relativePath: { eq: "product-page/evachill.png" }) {
      ...imageFragmentBlur
    }
    model2: file(relativePath: { eq: "home-page/model/model-2@2x.jpg" }) {
      ...imageFragmentBlur
    }
    model3: file(relativePath: { eq: "home-page/model/model-3@2.jpg" }) {
      ...imageFragmentBlur
    }
  }
`;

export default function Reasons() {
  const classes = useStyles();
  const compare = [
    {
      title: <FormattedMessage id="home_reasons.model1" />,
    },
    {
      title: <FormattedMessage id="home_reasons.model2" />,
    },
    {
      title: <FormattedMessage id="home_reasons.model3" />,
    },
  ];

  return (
    <Background>
      <Title>
        <FormattedMessage id="home_reasons.title" />
      </Title>
      <Container maxWidth="lg" className={classes.container} tabIndex={0}>
        <StaticQuery
          query={images}
          render={(data) => (
            <ComparisonTable
              data={list}
              header={compare}
              margins={false}
              images={[
                data.model1.childImageSharp.gatsbyImageData,
                data.model2.childImageSharp.gatsbyImageData,
                data.model3.childImageSharp.gatsbyImageData,
              ]}
            />
          )}
        />
      </Container>
    </Background>
  );
}
