import React from 'react';
import { LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import makeStyles from './make-styles';
import { colors } from './common';

const useStyles = makeStyles(() => ({
  colorPrimary: {
    backgroundColor: colors.color08,
  },
  barColorPrimary: {
    backgroundColor: colors.color05,
  },
}));

function Progress({ completed }) {
  const classes = useStyles();

  return (
    <LinearProgress
      style={{ height: 2 }}
      variant="determinate"
      value={completed}
      title="Slides progress"
      classes={{
        colorPrimary: classes.colorPrimary,
        barColorPrimary: classes.barColorPrimary,
      }}
    />
  );
}

Progress.propTypes = {
  completed: PropTypes.number.isRequired,
};

export default Progress;
