import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Title, TitleSmall } from '../../../ui/text';
import {
  isEvaChill,
  isEvaLightPlus,
  isEvaSmart,
} from '../../../utils/slug-checks';
import useStyles from './styled';
import Container from '../../../ui/Container';
import Card from './Card';

export default function Family() {
  const allItems = useSelector((state) => state.items.list);
  const items = [
    {
      value: allItems.find(isEvaChill),
      desc: 'family.item1_text',
    },
    {
      value: allItems.find(isEvaLightPlus),
      desc: 'family.item2_text',
    },
    {
      value: allItems.find(isEvaSmart),
      desc: 'family.item3_text',
    },
  ];

  const itemsList = [items[0], items[1]];
  const previewItem = items[2];

  const classes = useStyles();

  return (
    <Container>
      <Title>
        <FormattedMessage id="family.title" />
      </Title>
      <TitleSmall>
        <FormattedMessage id="family.title_small" />
      </TitleSmall>
      <Grid container className={classes.container} spacing={2}>
        {itemsList.map(
          (value) =>
            value && value.value && <Card key={value.value.id} data={value} />
        )}
      </Grid>
      <Grid container className={classes.container2}>
        {previewItem && previewItem.value && <Card data={previewItem} full />}
      </Grid>
    </Container>
  );
}
