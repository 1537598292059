import makeStyles from '../../../ui/make-styles';

export default makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  icon: {
    marginTop: 120,
    width: 82,
    height: 82,
  },
  title: {
    marginTop: 40,
    fontWeight: '300',
    fontSize: '32px',
    lineHeight: '170%',
    width: 568,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
      width: 'auto',
      padding: '0 96px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
      padding: '0 15px',
    },
  },
  button: {
    marginTop: 60,
    paddingBottom: 166,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 122,
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 100,
    },
  },
}));
