import React, { useContext, useEffect, useState } from 'react';
import { CarouselContext, ButtonBack, ButtonNext } from 'pure-react-carousel';
import Typography from '@material-ui/core/Typography';
import useStyles from './styled';
import { getMaxSlides } from './data';
import arrowImg from '../../../images/home-page/icon_other/arrow.svg';
import Progress from '../../../ui/progress';

function Nav() {
  const classes = useStyles();
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext.state.currentSlide
  );

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  const maxSlides = getMaxSlides();
  const slideIndex = currentSlide + 1;
  const progressText = `${slideIndex}/${maxSlides}`;
  const progressValue = (slideIndex / maxSlides) * 100;

  return (
    <div className={classes.container2}>
      <div className={classes.leftBlock}>
        <Typography paragraph variant="h6" className={classes.progressText}>
          {progressText}
        </Typography>
        <Progress completed={progressValue} />
      </div>
      <div className={classes.rightBlock}>
        <ButtonBack className={classes.selectBtn}>
          <img
            src={arrowImg}
            style={{ transform: 'rotate(180deg)' }}
            alt="arrow"
          />
        </ButtonBack>
        <ButtonNext className={classes.selectBtn}>
          <img src={arrowImg} alt="arrow" />
        </ButtonNext>
      </div>
    </div>
  );
}

export default Nav;
