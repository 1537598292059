import styled from 'styled-components';
import makeStyles from '../../../ui/make-styles';
import { mediaMax } from '../../../ui/common';

export const Headline = styled.h1`
  margin: 0;
  color: white;
  width: 650px;
  font-size: 52px;
  font-weight: 500;
  line-height: 122.9%;
  white-space: pre-wrap;
  ${mediaMax.tablet`
    width: 100%;
    font-size: 48px;
  `};
  ${mediaMax.phone`
    font-size: 32px;
    font-weight: 600;
  `};
`;

/* eslint-disable no-useless-computed-key */
export default makeStyles(() => ({
  root: {
    position: 'relative',
  },
  container: {
    position: 'absolute',
    top: '38%',
    ['@media (max-height: 400px)']: {
      top: '22%',
    },
    ['@media (max-width: 330px)']: {
      top: '25%',
    },
  },
  text: {
    marginTop: 20,
    width: 650,
    color: 'white !important',
    fontSize: '18px !important',
    ['@media (max-width: 740.95px)']: {
      width: '100%',
    },
  },
  button: {
    marginTop: 40,
    ['@media (max-height: 420px)']: {
      marginTop: 20,
    },
  },
}));
/* eslint-enable no-useless-computed-key */
