import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Title } from '../../../ui/text';
import TextUnderline from '../../../ui/text-underline';
import useStyles from './styled';
import Preview from './Preview';
import Container from '../../../ui/Container';
import {
  DesktopMin,
  BetweenPhoneDesktop,
  PhoneMax,
} from '../../../utils/mediaCheck';

function BrandNew() {
  const classes = useStyles();

  return (
    <Container>
      <Title>
        <FormattedMessage id="brandnew.title" />
      </Title>
      <DesktopMin>
        <Preview type={0} />
      </DesktopMin>
      <BetweenPhoneDesktop>
        <Preview type={1} />
      </BetweenPhoneDesktop>
      <PhoneMax>
        <Preview type={2} />
      </PhoneMax>
      <div className={classes.bottom}>
        <TextUnderline to="technology">
          <FormattedMessage id="brandnew.underline" />
        </TextUnderline>
      </div>
    </Container>
  );
}

export default BrandNew;
