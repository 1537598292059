import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Title, TitleSmall } from '../../../ui/text';
import Background from '../../../ui/background';
import ShopNow from '../../ShopNow/index';
import items from './data';
import useStyles from './styled';
import Container from '../../../ui/Container';

const images = graphql`
  query {
    preview: file(relativePath: { eq: "home-page/better/better-two@2.jpg" }) {
      ...imageFragmentBlur
    }
  }
`;

export default function Better() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Background className={classes.background} />
      <Container maxWidth="lg">
        <Title className={classes.title}>
          <FormattedMessage id="better.title" />
        </Title>
        <TitleSmall>
          <FormattedMessage id="better.title_small" />
        </TitleSmall>
        <div className={classes.button}>
          <ShopNow />
        </div>
        <StaticQuery
          query={images}
          render={(data) => (
            <GatsbyImage
              image={data.preview.childImageSharp.gatsbyImageData}
              alt=""
              className={classes.preview}
            />
          )}
        />
        <Typography paragraph variant="h6" className={classes.text}>
          <FormattedMessage id="better.text" />
        </Typography>
        <div className={classes.container}>
          {items.map((value) => (
            <img
              key={value[1]}
              src={value[0]}
              className={classes.brandIcon}
              alt={value[1]}
            />
          ))}
        </div>
      </Container>
    </div>
  );
}
