const images = [
  {
    image: 'new_img2',
    avatar: 'new_img2_a',
    name: 'Raphaela',
    userName: '@overpackedsuitcase',
    link: 'https://www.instagram.com/p/CMHUxRyBIce/',
    profileLink: 'https://www.instagram.com/overpackedsuitcase/',
  },
  {
    image: 'new_img1',
    avatar: 'new_img1_a',
    name: 'Luke & Jess',
    userName: '@_aswewander',
    link: 'https://www.instagram.com/p/CK-1a_ABLsH/',
    profileLink: 'https://www.instagram.com/_aswewander/',
  },
  {
    image: 'new_img11',
    avatar: 'new_img11_a',
    name: 'Rochelle Smith',
    userName: '@justanothermannequin',
    link: 'https://www.instagram.com/p/CLyZOtmHRGf/',
    profileLink: 'https://www.instagram.com/justanothermannequin/',
  },
  {
    image: 'new_img12',
    avatar: 'new_img12_a',
    name: 'Daia Larie',
    userName: '@daialarie',
    link: 'https://www.instagram.com/p/CMcJ5A5HY7j/',
    profileLink: 'https://www.instagram.com/daialarie/',
  },
  {
    image: 'new_img4',
    avatar: 'new_img4_a',
    name: 'E L I S E',
    userName: '@elisehalina',
    link: 'https://www.instagram.com/p/CHXE4TWhU7b/',
    profileLink: 'https://www.instagram.com/elisehalina/',
  },
  {
    image: 'new_img3',
    avatar: 'new_img3_a',
    name: 'anniesbucketlist',
    userName: '@anniesbucketlist',
    link: 'https://www.instagram.com/p/CHuVLH6guBt/',
    profileLink: 'https://www.instagram.com/anniesbucketlist/',
  },
  {
    image: 'new_img5',
    avatar: 'new_img5_a',
    name: 'Carly McDonagh',
    userName: '@carlyamcdonagh',
    link: 'https://www.instagram.com/p/CH7XdnOMjCH/',
    profileLink: 'https://www.instagram.com/carlyamcdonagh/',
  },
  {
    image: 'new_img6',
    avatar: 'new_img6_a',
    name: 'matteacarson',
    userName: '@matteacarson',
    link: 'https://www.instagram.com/p/CIA2270hYDL/',
    profileLink: 'https://www.instagram.com/matteacarson/',
  },
  {
    image: 'new_img7',
    avatar: 'new_img7_a',
    name: 'Jamie and Camille',
    userName: '@all.about.adventure',
    link: 'https://www.instagram.com/p/CIcqGwPg97g/',
    profileLink: 'https://www.instagram.com/wanderingwilsons/',
  },
  {
    image: 'new_img8',
    avatar: 'new_img8_a',
    name: 'Sue Kangaroo',
    userName: '@suekang_',
    link: 'https://www.instagram.com/p/CIekSEWM-yn/',
    profileLink: 'https://www.instagram.com/suekang_/',
  },
  {
    image: 'new_img9',
    avatar: 'new_img9_a',
    name: 'Liz Carlson',
    userName: '@youngadventuress',
    link: 'https://www.instagram.com/p/CKSVd7gpC_7/',
    profileLink: 'https://www.instagram.com/youngadventuress/',
  },
  {
    image: 'new_img10',
    avatar: 'new_img10_a',
    name: 'Mads & Jeff',
    userName: '@wanderingwilsons',
    link: 'https://www.instagram.com/p/CKmuzmIBaJI/',
    profileLink: 'https://www.instagram.com/wanderingwilsons/',
  },
  {
    image: 'new_img13',
    avatar: 'new_img13_a',
    name: 'Goose & Ellen',
    userName: '@gooseandellen',
    link: 'https://www.instagram.com/p/CMklshfBEzV/',
    profileLink: 'https://www.instagram.com/gooseandellen/',
  },
  {
    image: 'new_img14',
    avatar: 'new_img14_a',
    name: 'Michelle Lawrence',
    userName: '@michelle.lawrencee',
    link: 'https://www.instagram.com/p/CNaicwNB5f7/',
    profileLink: 'https://www.instagram.com/michelle.lawrencee/',
  },
  {
    image: 'img1',
    avatar: 'img1_a',
    name: 'Moose', // ':feet: :heart: Moose :heart: :feet:',
    userName: '@moose_golden',
    link: 'https://www.instagram.com/p/B0uPWbABTS0/',
    profileLink: 'https://www.instagram.com/moose_golden/',
  },
  {
    image: 'img2',
    avatar: 'img2_a',
    name: 'Twins Evie & Aria',
    userName: '@evie_and_aria',
    link: 'https://www.instagram.com/p/Bb2iJJZFlAR/?igshid=1ltjmu2cxqjyb',
    profileLink: 'https://www.instagram.com/evie_and_aria/',
  },
  {
    image: 'img3',
    avatar: 'img3_a',
    name: 'Elias',
    userName: '@myfriendelias',
    link: 'https://www.instagram.com/p/Bem5hpylBoJ/?igshid=9mf41tzzlt4v',
    profileLink: 'https://www.instagram.com/myfriendelias/',
  },
  {
    image: 'img4',
    avatar: 'img4_a',
    name: 'The Donnellans',
    userName: '@thedonnellans',
    link: 'https://www.instagram.com/p/B8TV2qGHhEI/',
    profileLink: 'https://www.instagram.com/thedonnellans/',
  },
  {
    image: 'img5',
    avatar: 'img5_a',
    name: 'TravellingK Caravanning Kiwi', // 'TravellingK :minibus: Caravanning Kiwi',
    userName: '@travellingk',
    link: 'https://www.instagram.com/p/B8cALAWJJ0M/',
    profileLink: 'https://www.instagram.com/travellingk/',
  },
  {
    image: 'img6',
    avatar: 'img6_a',
    name: 'OverlandTravellers Australia', // 'OverlandTravellers :sparkles: Australia',
    userName: '@overlandtravellers',
    link: 'https://www.instagram.com/p/B6PVRXhA_Dp/?igshid=1jz09dmd4q14f',
    profileLink: 'https://www.instagram.com/overlandtravellers/',
  },
];

export default images;
export const getMaxSlides = () => images.length;
