import makeStyles from '../../../ui/make-styles';

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: 0,
    paddingBottom: 151,
  },
  title: {
    paddingTop: 86,
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 28,
    [theme.breakpoints.down('md')]: {
      paddingTop: 92,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 100,
    },
  },
  paper: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  icon: {
    width: 128,
    height: 128,
  },
  descTitle: {
    marginTop: 16,
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 28,
    },
  },
  descText: {
    marginTop: 8,
    fontSize: 14,
    fontWeight: 300,
    whiteSpace: 'pre-wrap',
    padding: '0 30px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
      padding: '0 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px',
    },
  },
  container: {
    marginTop: 64,
  },
}));
