import makeStyles from '../../../ui/make-styles';

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
  },
  title: {
    width: 600,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  button: {
    textAlign: 'center',
    marginTop: 32,
  },
  preview: {
    display: 'block',
    marginTop: 69,
    margin: '0 auto',
    borderRadius: 4,
    maxWidth: '726px',
  },
  background: {
    position: 'absolute',
    top: 470,
    left: 0,
    width: '100%',
    height: 'calc(100% - 370px)',
    zIndex: -1,
  },
  text: {
    marginTop: 120,
    width: 800,
    margin: '0 auto',
    fontWeight: 300,
    fontSize: '32px !important',
    lineHeight: '170%',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: 80,
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 60,
      fontSize: '20px !important',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 60,
    marginBottom: 120,
  },
  brandIcon: {
    display: 'block',
    width: 'auto',
    height: 20,
    padding: '0 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px',
    },
  },
}));
