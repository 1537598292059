import benefit1 from '../../../images/home-page/sofa/sofa-1.svg';
import benefit2 from '../../../images/home-page/sofa/sofa-2.svg';
import benefit3 from '../../../images/home-page/sofa/sofa-3.svg';
import benefit4 from '../../../images/home-page/sofa/sofa-4.svg';

export default [
  [benefit1, 'benefits.item1', 'benefits.text1'],
  [benefit2, 'benefits.item2', 'benefits.text2'],
  [benefit3, 'benefits.item3', 'benefits.text3'],
  [benefit4, 'benefits.item4', 'benefits.text4'],
];
