import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { FormattedMessage } from 'react-intl';
import ShopNow from '../../ShopNow/index';
import useStyles, { Headline } from './styled';

function Content() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <div className={classes.container}>
        <Headline>
          <FormattedMessage id="header.title" />
        </Headline>
        <Typography paragraph variant="h6" className={classes.text}>
          <FormattedMessage id="header.text" />
        </Typography>
        <div className={classes.button}>
          <ShopNow extended />
        </div>
      </div>
    </Container>
  );
}

export default Content;
