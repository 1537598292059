import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import useStyles from './styled';
import { items, tabletItems } from './data';
import Description from './Description';
import genKey from '../../../utils/gen-key';

const images = graphql`
  query {
    ver1: file(relativePath: { eq: "home-page/brand_new/brand-new@2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ver2: file(relativePath: { eq: "home-page/brand_new/brand-new_2@2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ver3: file(relativePath: { eq: "home-page/brand_new/brand-new_3@2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const imgList = ['ver1', 'ver2', 'ver3'];

function Preview({ type }) {
  const classes = useStyles();
  const img = imgList[type];

  let itemsList = [...items];

  if (type > 0) itemsList = tabletItems;

  return (
    <StaticQuery
      query={images}
      render={(data) => (
        <BackgroundImage
          fluid={data[img].childImageSharp.fluid}
          className={classnames(
            classes.img,
            type === 1 ? classes.img2 : null,
            type === 2 ? classes.img3 : null
          )}
        >
          {itemsList.map((value, i) => (
            <Description value={value} type={type} key={genKey()} iter={i} />
          ))}
        </BackgroundImage>
      )}
    />
  );
}

Preview.propTypes = {
  type: PropTypes.number.isRequired,
};

export default Preview;
