import makeStyles from '../../../ui/make-styles';

/* eslint-disable no-useless-computed-key */
export default makeStyles((theme) => ({
  img: {
    marginTop: 48,
    position: 'relative',
    width: 748,
    height: 599,
    left: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    transform: 'translateX(-50%)',
  },
  img2: {
    top: 20,
    left: '38%',
    width: 502,
    height: 547,
  },
  img3: {
    top: 20,
    left: '-1%',
    transform: 'translateX(0%)',
    width: 197,
    height: 360,
    [theme.breakpoints.down('sm')]: {
      left: '-5%',
    },
    ['@media (max-width: 370.95px)']: {
      left: '-20%',
    },
  },
  bottom: {
    marginTop: 29,
    paddingBottom: 120,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 100,
    },
  },
}));
/* eslint-enable no-useless-computed-key */
