const items = [
  {
    title: 'brandnew.item1',
    text: 'brandnew.item2',
    pos: [-150, 100],
  },
  {
    title: 'brandnew.item3',
    text: 'brandnew.item4',
    pos: [-200, 265],
  },
  {
    title: 'brandnew.item5',
    text: 'brandnew.item6',
    pos: [-160, 410],
  },
  {
    title: 'brandnew.item7',
    text: 'brandnew.item8',
    pos: [740, 125],
  },
  {
    title: 'brandnew.item9',
    text: 'brandnew.item10',
    pos: [770, 295],
  },
  {
    title: 'brandnew.item11',
    text: 'brandnew.item12',
    pos: [720, 455],
  },
];

const tabletItems = [
  {
    title: 'brandnew.item1',
    text: 'brandnew.item2',
    pos: [-150, 100],
    tabletYOffset: 60,
  },
  {
    title: 'brandnew.item3',
    text: 'brandnew.item4',
    pos: [-200, 265],
    tabletYOffset: 35,
  },
  {
    title: 'brandnew.item5',
    text: 'brandnew.item6',
    pos: [-160, 410],
    tabletYOffset: 25,
  },
  {
    title: 'brandnew.item9',
    text: 'brandnew.item10',
    pos: [720, 455],
    tabletYOffset: 10,
  },
  {
    title: 'brandnew.item7',
    text: 'brandnew.item8',
    pos: [740, 125],
    tabletYOffset: -20,
  },
];

export { items, tabletItems };
