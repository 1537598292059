import makeStyles from '../../../ui/make-styles';

export default makeStyles((theme) => ({
  container: {
    overflow: 'auto',
    marginTop: 73,
    paddingBottom: 140,
    [theme.breakpoints.down('sm')]: {
      marginTop: 61,
      paddingBottom: 100,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 17,
    },
  },
}));
