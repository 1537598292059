import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors, mediaMax } from '../../../ui/common';

const Block = styled.div`
  position: absolute;
  color: ${colors.color05};
  ${mediaMax.phone`
    margin-top: 5px;
  `};
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 500;
  width: 300px;
  ${mediaMax.medium`
    font-size: 18px;
    width: 200px;
  `};
  ${mediaMax.phone`
    font-size: 14px;
    width: 160px;
  `};
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4;
  max-width: 350px;
  width: 100%;
  padding-top: 5px;
  ${mediaMax.phone`
    font-size: 12px;
    width: 170px;
  `};
`;

function Description({ value, type, iter }) {
  const { title, text, pos, tabletYOffset = 0 } = value;

  /* eslint-disable-next-line prefer-const */
  let position = { left: pos[0], top: pos[1] };

  /* use different mode */
  if (type > 0) {
    if (type === 1) {
      position.left = 430;
      position.top = 55 + (iter * 97 + tabletYOffset);
    } else if (type === 2) {
      position.left = 200;
      position.top = 87 + iter * 97;
    }
  }

  return (
    <Block
      css={css`
        transform: translate(
          ${position.left.toString()}px,
          ${position.top.toString()}px
        );
      `}
    >
      <Title>
        <FormattedMessage id={title} />
      </Title>
      <Text>
        <FormattedMessage id={text} />
      </Text>
    </Block>
  );
}

Description.propTypes = {
  value: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.number.isRequired,
  iter: PropTypes.number.isRequired,
};

export default Description;
