import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colorsBar, colors } from './common';

const RadioBackground = styled.div`
  position: relative;
  display: inline-block !important;
  border-radius: 50%;
  background: transparent;
  border: 1px solid
    ${({ isActive }) => (isActive ? colors.color11 : '#000000000')};
  width: 30px;
  height: 30px;
  margin: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid
      ${({ isActive }) => (isActive ? colors.color11 : '#37373733')};
  }
`;

const RadioFill = styled.div`
  display: inline-block !important;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  ${({ color }) => color && `background: ${color};`}
`;

function Radio({ isActive, active, onClick }) {
  return (
    <RadioBackground onClick={onClick} isActive={isActive}>
      <RadioFill color={colorsBar[active]} />
    </RadioBackground>
  );
}

Radio.propTypes = {
  isActive: PropTypes.bool.isRequired,
  active: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function CustomRadioGroup({ items, selected, onClick }) {
  return (
    <>
      {items.map((el) => (
        <Radio
          key={el}
          onClick={() => onClick(el)}
          isActive={el === selected}
          active={el}
        />
      ))}
    </>
  );
}

CustomRadioGroup.propTypes = {
  selected: PropTypes.string.isRequired, // eslint-disable-line react/forbid-prop-types
  onClick: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default CustomRadioGroup;
