import React from 'react';
import { CarouselProvider } from 'pure-react-carousel';
import { FormattedMessage } from 'react-intl';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Title, TitleSmall } from '../../../ui/text';
import useStyles from './styled';
import { getMaxSlides } from './data';
import Slides from './Slides';
import Nav from './Nav';

export default function Persons() {
  const classes = useStyles();
  const maxSlides = getMaxSlides();

  return (
    <>
      <div className={classes.root}>
        <Title>
          <FormattedMessage id="persons.title" />
        </Title>
        <TitleSmall>
          <FormattedMessage id="persons.title_small" />
        </TitleSmall>
      </div>
      <div className={classes.container}>
        <CarouselProvider
          naturalSlideWidth={255}
          naturalSlideHeight={325}
          totalSlides={maxSlides}
          visibleSlides={1}
          currentSlide={3}
          dragEnabled={false}
          touchEnabled
        >
          <Slides />
          <Nav />
        </CarouselProvider>
      </div>
    </>
  );
}
