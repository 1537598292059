import PropTypes from 'prop-types';
import React from 'react';
import Header from '../components/Home/header/index';
import Benefits from '../components/Home/benefits/index';
import Brandnew from '../components/Home/brandnew/index';
import Reasons from '../components/Home/reasons';
import Family from '../components/Home/family/index';
import Better from '../components/Home/better/index';
import Persons from '../components/Home/persons/index';
import Trust from '../components/Home/trust/index';
import Blog from '../components/Blog';
import Footer from '../components/Footer';

function IndexPage({ pageContext }) {
  return (
    <>
      <main>
        <Header />
        <Family />
        <Benefits />
        <Brandnew />
        <Reasons />
        <Better />
        <Persons />
        <Trust />
        <Blog list={pageContext.blogList} />
      </main>
      <Footer />
    </>
  );
}
IndexPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pageContext: PropTypes.object.isRequired,
};

export default IndexPage;
