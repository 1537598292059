import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import RadioCustom from '../../../ui/radio';
import {
  getItemFullImage,
  getItemColorList,
  getItemName,
} from '../../../utils/api/items';
import useI18nNavigation from '../../../hooks/use-i18n-navigation';
import { onClickProductDataLayer } from '../../../utils/datalayer/dataLayer';
import { ButtonBigSecondary } from '../../../ui/buttonDefault';
import useStyles from './styled';
import useItemsData from '../../Products/Info/use-items-data';

function getPath(productName) {
  return productName.replace(/ .*/, '').toLowerCase();
}

/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable  jsx-a11y/click-events-have-key-events */
function Card({ data, full }) {
  const { value, desc } = data;

  const classes = useStyles();

  const name = getItemName(value);
  const { data: itemsData, setColor } = useItemsData(name);

  const container = classNames(
    classes.cardContainer,
    full ? classes.cardContainer2 : null
  );
  const buttonGroup = classNames(classes.button, full ? classes.button2 : null);

  const colors = getItemColorList(value);
  const selectedColor = itemsData.color;
  const imgBig = getItemFullImage(value, selectedColor);
  const navigate = useI18nNavigation();
  const onLearnMore = () => {
    onClickProductDataLayer(value);
    navigate(`/${getPath(name)}`);
  };

  const jsxMain = (
    <>
      <Typography paragraph variant="h5" className={classes.itemTitle}>
        {name}
      </Typography>
      <Typography paragraph variant="h6" className={classes.itemText}>
        <FormattedMessage id={desc} />
      </Typography>
      {colors && (
        <div className={classes.radioGroup}>
          {itemsData.color !== null && (
            <RadioCustom
              key={name}
              selected={itemsData.color}
              items={itemsData.colors}
              onClick={(v) => setColor(v)}
            />
          )}
        </div>
      )}
      <div className={buttonGroup}>
        <ButtonBigSecondary onClick={onLearnMore} extended>
          <FormattedMessage id="family.learnmore" />
        </ButtonBigSecondary>
      </div>
    </>
  );

  return full ? (
    <Grid item xs={12} className={classes.grid}>
      <div className={classNames(container, classes.containerFull)}>
        <div className={classes.itemFull}>{jsxMain}</div>
        <div className={classes.itemFull}>
          <img className={classes.fullImg} src={imgBig} alt="icon" />
        </div>
      </div>
    </Grid>
  ) : (
    <Grid item md={6} xs={12} className={classes.grid}>
      <div className={container}>
        <img src={imgBig} className={classes.imgPreview} alt="icon" />
        {jsxMain}
      </div>
    </Grid>
  );
}
/* eslint-enable jsx-a11y/interactive-supports-focus */
/* eslint-enable jsx-a11y/click-events-have-key-events */

Card.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  full: PropTypes.bool,
};

Card.defaultProps = {
  full: false,
};

export default Card;
