import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import Background from '../../../ui/background';
import boxImg from '../../../images/home-page/icon_other/icon-box.svg';
import useStyles from './styled';
import ShopNow from '../../ShopNow/index';

export default function Trust() {
  const classes = useStyles();

  return (
    <Background className={classes.root}>
      <img src={boxImg} alt="icon" className={classes.icon} />
      <Typography paragraph variant="h6" className={classes.title}>
        <FormattedMessage id="trust.title" />
      </Typography>
      <div className={classes.button}>
        <ShopNow />
      </div>
    </Background>
  );
}
