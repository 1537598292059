import makeStyles from '../../../ui/make-styles';

import { colors } from '../../../ui/common';

export default makeStyles((theme) => ({
  root: {
    overflow: 'auto',
  },
  slider_wrap: {
    width: 'calc(255px + 30px)',
    paddingLeft: '50%',
    paddingRight: '50%',
    transform: 'translateX(calc(-230px/2 - 30px/2))',
  },
  container: {
    marginTop: 73,
    marginBottom: 144,
    overflow: 'hidden',
  },
  paper: {
    borderRadius: 6,
    width: 255,
    height: 325,
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.1)',
  },
  img: {
    width: 255,
    height: 257,
    borderRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  icon: {
    position: 'absolute',
    top: 13,
    left: 220,
    zIndex: 1,
  },
  avatar: {
    position: 'absolute !important',
    top: 270,
    left: 12,
    zIndex: 1,
    width: 38,
    background: '#FFFFFF',
    'border-radius': 20,
  },
  itemTitle: {
    position: 'absolute',
    top: 270,
    left: 60,
    zIndex: 1,
    fontSize: 12,
    fontWeight: 600,
  },
  itemDesc: {
    position: 'absolute',
    top: 295,
    left: 60,
    zIndex: 1,
    fontSize: '10px',
    opacity: 0.7,
    color: colors.color01,
  },
  container2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 20,
    width: 556,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  leftBlock: {
    flexBasis: '40%',
    paddingRight: 40,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 20,
    },
  },
  rightBlock: {
    paddingTop: 40,
  },
  selectBtn: {
    backgroundColor: 'Transparent',
    border: 'none',
    overflow: 'hidden',
    outline: 'none',
  },
  progressText: {
    fontWeight: '300',
    fontSize: '16px',
  },
  buttons: {
    paddingTop: 32,
    paddingLeft: 40,
  },
}));
