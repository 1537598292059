import React from 'react';
import { Slider, Slide } from 'pure-react-carousel';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import useStyles from './styled';
import genKey from '../../../utils/gen-key';
import images from './data';
import instImg from '../../../images/social/social-instagram.svg';

const imagesQuery = graphql`
  query {
    img1: file(relativePath: { eq: "home-page/person/person-1@2.jpg" }) {
      ...imageFragmentBlur
    }
    img1_a: file(relativePath: { eq: "home-page/person/person-1-avatar.jpg" }) {
      ...imageFragmentBlur
    }
    img2: file(relativePath: { eq: "home-page/person/person-2@2.jpg" }) {
      ...imageFragmentBlur
    }
    img2_a: file(relativePath: { eq: "home-page/person/person-2-avatar.jpg" }) {
      ...imageFragmentBlur
    }
    img3: file(relativePath: { eq: "home-page/person/person-3@2.jpg" }) {
      ...imageFragmentBlur
    }
    img3_a: file(relativePath: { eq: "home-page/person/person-3-avatar.jpg" }) {
      ...imageFragmentBlur
    }
    img4: file(relativePath: { eq: "home-page/person/person-4@2.jpg" }) {
      ...imageFragmentBlur
    }
    img4_a: file(relativePath: { eq: "home-page/person/person-4-avatar.jpg" }) {
      ...imageFragmentBlur
    }
    img5: file(relativePath: { eq: "home-page/person/person-5@2.jpg" }) {
      ...imageFragmentBlur
    }
    img5_a: file(relativePath: { eq: "home-page/person/person-5-avatar.jpg" }) {
      ...imageFragmentBlur
    }
    img6: file(relativePath: { eq: "home-page/person/person-6@2.jpg" }) {
      ...imageFragmentBlur
    }
    img6_a: file(relativePath: { eq: "home-page/person/person-6-avatar.jpg" }) {
      ...imageFragmentBlur
    }
    new_img1: file(relativePath: { eq: "home-page/person/aswewander.jpg" }) {
      ...imageFragmentBlur
    }
    new_img1_a: file(
      relativePath: { eq: "home-page/person/aswewander_a.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img2: file(
      relativePath: { eq: "home-page/person/overpackedsuitcase.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img2_a: file(
      relativePath: { eq: "home-page/person/overpackedsuitcase_a.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img3: file(
      relativePath: { eq: "home-page/person/anniebucketlist.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img3_a: file(
      relativePath: { eq: "home-page/person/anniebucketlist_a.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img4: file(relativePath: { eq: "home-page/person/elisehalina.jpg" }) {
      ...imageFragmentBlur
    }
    new_img4_a: file(
      relativePath: { eq: "home-page/person/elisehalina_a.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img5: file(relativePath: { eq: "home-page/person/carlymac.jpg" }) {
      ...imageFragmentBlur
    }
    new_img5_a: file(relativePath: { eq: "home-page/person/carlymac_a.jpg" }) {
      ...imageFragmentBlur
    }
    new_img6: file(relativePath: { eq: "home-page/person/mattearchson.jpg" }) {
      ...imageFragmentBlur
    }
    new_img6_a: file(
      relativePath: { eq: "home-page/person/mattearchson_a.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img7: file(
      relativePath: { eq: "home-page/person/allaboutadventure.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img7_a: file(
      relativePath: { eq: "home-page/person/allaboutadventure_a.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img8: file(relativePath: { eq: "home-page/person/suekang.jpg" }) {
      ...imageFragmentBlur
    }
    new_img8_a: file(relativePath: { eq: "home-page/person/suekang_a.jpg" }) {
      ...imageFragmentBlur
    }
    new_img9: file(
      relativePath: { eq: "home-page/person/youngadventures.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img9_a: file(
      relativePath: { eq: "home-page/person/youngadventures_a.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img10: file(
      relativePath: { eq: "home-page/person/wanderingwilsons.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img10_a: file(
      relativePath: { eq: "home-page/person/wanderingwilsons_a.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img11: file(
      relativePath: { eq: "home-page/person/justanothermanquin.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img11_a: file(
      relativePath: { eq: "home-page/person/justanothermanquin_a.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img12: file(relativePath: { eq: "home-page/person/dailaarie.jpg" }) {
      ...imageFragmentBlur
    }
    new_img12_a: file(
      relativePath: { eq: "home-page/person/dailaarie_a.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img13: file(
      relativePath: { eq: "home-page/person/gooseandellen.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img13_a: file(
      relativePath: { eq: "home-page/person/gooseandellen_a.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img14: file(
      relativePath: { eq: "home-page/person/michellelawrence.jpg" }
    ) {
      ...imageFragmentBlur
    }
    new_img14_a: file(
      relativePath: { eq: "home-page/person/michellelawrence_a.jpg" }
    ) {
      ...imageFragmentBlur
    }
  }
`;

function Slides() {
  const classes = useStyles();

  return (
    <StaticQuery
      query={imagesQuery}
      render={(data) => (
        <Slider className={classes.slider_wrap}>
          {images.map((value, i) => (
            <Slide
              key={genKey()}
              index={i}
              role={undefined}
              ariaLabel={undefined}
              aria-label={undefined}
              ariaSelected={undefined}
              aria-selected={undefined}
            >
              <div className={classes.paper}>
                <div style={{ position: 'relative' }}>
                  <Link href={value.link}>
                    <img
                      src={instImg}
                      className={classes.icon}
                      alt={`Instagram of ${value.userName}`}
                    />
                  </Link>
                  <GatsbyImage
                    image={data[value.avatar].childImageSharp.gatsbyImageData}
                    alt=""
                    className={classes.avatar}
                  />
                  <Link href={value.link}>
                    <Typography
                      paragraph
                      variant="h6"
                      className={classes.itemTitle}
                    >
                      {value.name}
                    </Typography>
                  </Link>
                  <Link href={value.link}>
                    <Typography
                      paragraph
                      variant="h6"
                      className={classes.itemDesc}
                    >
                      {value.userName}
                    </Typography>
                  </Link>
                </div>
                <GatsbyImage
                  image={data[value.image].childImageSharp.gatsbyImageData}
                  alt=""
                  className={classes.img}
                />
              </div>
            </Slide>
          ))}
        </Slider>
      )}
    />
  );
}

export default Slides;
