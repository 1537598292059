import better1 from '../../../images/home-page/brands/brand-1.svg';
import better2 from '../../../images/home-page/brands/brand-2.svg';
import better3 from '../../../images/home-page/brands/brand-3.svg';
import better4 from '../../../images/home-page/brands/brand-4.svg';

const items = [
  [better1, 'Forbes'],
  [better2, 'PC magazine'],
  [better3, 'TNW'],
  [better4, 'The Guardian'],
];

export default items;
